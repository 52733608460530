import React, { useEffect, useRef } from "react";
import "../styles/Stacking.css";
import frameA from "../Attachments/IMG_6579.jpeg";
import frameB from "../Attachments/Event-j.jpeg";
import frameC from "../Attachments/Untitled design (2).png";

const StackingEffect: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const rows = containerRef.current.querySelectorAll(".stacking-row");

        rows.forEach((row, index) => {
          if (containerTop <= -index * 100) {
            row.classList.add("stacked");
          } else {
            row.classList.remove("stacked");
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={containerRef} className="stacking-containers">

      <div className="stacking-row row-1">

        <div className="header-image-container">
          <div className="left">
            <div className="number-header">01</div>
            <div className="subheading">
              <div className="circle" />
              <p className="subheading-text">RETAILERS</p>
            </div>
            <h3 className="bold-heading">RETAILERS</h3>
          </div>
          <div className="right">
            <img src={frameA} alt="Row 1 Image" />
          </div>
        </div>


        <div className="line-container">
          <hr className="line-separator" />
        </div>

        <div className="body-container">
          <p className="body-texts">
          Maximize your retail potential with Jyve’s comprehensive and tailored services, designed specifically for leading retailers like WinCo and Safeway. Our offerings include efficient stocking, precise inventory management, detailed audits, expertly crafted planograms, and seamless resets. With Jyve, you can optimize store operations, ensure product availability, and enhance the shopping experience for your customers, driving measurable results and lasting success.
          </p>
        </div>
      </div>

      <div className="stacking-row row-2">
        <div className="header-image-container">
          <div className="left">
            <div className="number-header">02</div>
            <div className="subheading">
              <div className="circle" />
              <p className="subheading-text">BRANDS</p>
            </div>
            <h3 className="bold-heading">BRANDS</h3>
          </div>
          <div className="right">
            <img src={frameC} />
          </div>
        </div>

        <div className="line-container">
          <hr className="line-separator" />
        </div>

        <div className="body-container">
          <p className="body-texts">
           
          Jyve provides highly skilled workers dedicated to enhancing retail operations by efficiently stocking shelves, optimizing product placement for maximum visibility,
           constructing eye-catching displays, and ensuring shelf integrity is consistently maintained. With Jyve’s expertise, retailers can boost sales potential, create engaging shopping experiences, and maintain a well-organized store environment that drives customer satisfaction.
              
          </p>
        </div>
      </div>

      <div className="stacking-row row-3">
        <div className="header-image-container">
          <div className="left">
            <div className="number-header">03</div>
            <div className="subheading">
              <div className="circle" />
              <p className="subheading-text">EVENTS</p>
            </div>
            <h3 className="bold-heading">EVENTS</h3>
          </div>
          <div className="right">
            <img src={frameB} alt="Row 3 Image" />
          </div>
        </div>

        <div className="line-container">
          <hr className="line-separator" />
        </div>

        <div className="body-container">
          <p className="body-texts">
          Events demand extensive preparation and a skilled team to ensure everything runs smoothly. Whether it’s concerts, weddings, or other large-scale gatherings, Jyve provides qualified professionals 
          to handle essential tasks such as parking management, bartending, merchandise sales, cleanup, and more. With Jyve, you can focus on creating memorable experiences while we take care of the operational details.
          </p>
        </div>
      </div>
      
      
    </div>
  );
};

export default StackingEffect;
