import React from 'react';
import '../styles/calltodownload.css';
import playstore from '../Attachments/Custom SVG/google-play.4cd60f9c4ff9c9b118956d7eceaf3d88.svg';
import apple from '../Attachments/Custom SVG/app-store.6f080d467427024b8f1ab3b49cfd1e25.svg';

interface AppDownloadProps {
  headerText?: string;
  subHeaderText?: string;
  descriptionText?: string;
  googlePlayUrl?: string;
  appleStoreUrl?: string;
  isButton?: boolean; 
}

const AppDownload: React.FC<AppDownloadProps> = ({
  headerText = "Download Our App Now",
  subHeaderText = "Download Our App",
  descriptionText = "Begin your jyving journey today by downloading our app and following the steps to get started",
  googlePlayUrl = "https://play.google.com/store/apps/details?id=com.app.jyve",
  appleStoreUrl = "https://apps.apple.com/us/app/jyve/id1033888450",
  isButton = false, 
}) => {
  return (
    <div id='scroll-target' className="flex justify-evenly items-center p-8 mx-8 flex-wrap">
      <div className="flex-1 textcontainer">
        <p className="text-white-600 text-base mb-4">{subHeaderText}</p>
        <h2 className="download-text font-bold text-white mb-4">{headerText}</h2>
        <p className="text-white-600 text-base mb-4">{descriptionText}</p>
      </div>

      <div className="flex justify-end gap-8">
   
        {isButton ? (
          <a
            href="mailto:sales@jyve.com"
            className="bg-green-900 text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-teal-600 transition-all"
          >
            Contact Us
          </a>
        ) : (
          <>
          
            <div>
              <a
                href={googlePlayUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transform transition-all"
              >
                <img
                  src={playstore}
                  alt="Google Play"
                  className="w-32 h-auto"
                />
              </a>
            </div>

          
            <div>
              <a
                href={appleStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transform transition-all"
              >
                <img
                  src={apple}
                  alt="Apple App Store"
                  className="w-32 h-auto"
                />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AppDownload;
