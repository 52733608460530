import { useState, useEffect } from 'react';
import phone from '../Attachments/Soft Minimalist Download Phone Mockup (2).png';
import backgrounDark from "../Attachments/DARK BACKGROUND (1).png";
import '../styles/hero.css';
import { useNavigate, useLocation } from 'react-router-dom';
import {scrollToElement} from "../utils/scrollElement";

interface HeroSectionProps {
  phoneImageSrc?: string;
  headingText?: string;
  subheadingText?: string;
  buttonText?: string;
  buttonLink?: string;
  beginJourneyText?: string;
  phoneImageStyle?: React.CSSProperties; 
}

const HeroSection = ({
  phoneImageSrc = phone, 
  headingText = "Join Jyve to choose when, where and how often you work.", 
  subheadingText = "Experience the future of technology today. Our app is designed to help you achieve more.", // Default subheading text
  buttonText = "Download Our App", 
  // buttonLink = "https://apps.apple.com/us/app/jyve/id1033888450",
  beginJourneyText = "Begin your jyving experience", 
  phoneImageStyle = {} 
}: HeroSectionProps) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const maxOffset = 50;
  const transformX = Math.min(Math.max((mousePosition.x - window.innerWidth / 2) / 20, -maxOffset), maxOffset);
  const transformY = Math.min(Math.max((mousePosition.y - window.innerHeight / 2) / 20, -maxOffset), maxOffset);

  const targetId = 'scroll-target';

  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    if (location.pathname === '/workers') {
      scrollToElement(targetId);
    } else {
      navigate('/', { state: { scrollToElementId: targetId } });
    }
  };

  return (
    <section className="relative flex justify-between items-center pt-[200px] heighter pb-16 text-white overflow-hidden">
      
   
      <div className="texts-container space-y-8 z-10 pl-8 bg-dark text-white">
        <p className="text-lg font-semibold">{beginJourneyText}</p>
        <h1 className="font-extrabold leading-tight">
          {headingText}
        </h1>
        <p className="text-lg">
          {subheadingText}
        </p>
        <button
          onClick={handleClick}
          rel="noopener noreferrer"
        >
          <button className="mt-4 px-6 py-3 bg-green-900 text-white rounded-lg shadow-lg transition duration-200">
            {buttonText}
          </button>
        </button>
      </div>

 
      <div
        className="phone-container"
        style={{
          transform: `translate3d(${transformX}px, ${transformY}px, 0)`,
          transition: 'transform 0.1s ease-out',
          ...phoneImageStyle,
        }}
      >
        <img
          src={phoneImageSrc}
          alt="Phone Mockup"
          className="phone-image"
        />
      </div>
    </section>
  );
};


HeroSection.defaultProps = {
  phoneImageSrc: phone,
  headingText: "Join Jyve to choose when, where and how often you work.",
  subheadingText: "Experience the future of technology today. Our app is designed to help you achieve more.",
  buttonText: "Download Our App",
  buttonLink: "https://apps.apple.com/us/app/jyve/id1033888450",
  beginJourneyText: "Begin your jyving experience",
  phoneImageStyle: {}, 
};

export default HeroSection;
