import Home from './Pages/Home';
import Brands from './Pages/Brands';
import Services from './Pages/Workers';
import Privacy from './Pages/Privacy';
import Msa from './Pages/Msa';
import Terms from './Pages/JyveTerms';
import Contact from './Pages/ContactUs'


export interface Route {
  path: string;
  element: React.ReactNode;
  name: string; 
}

const routes: Route[] = [
  { path: '/', element: <Home />, name: 'Home' },
  { path: '/brands', element: <Brands />, name: ' Brands/Retailers' },
  { path: '/workers', element: <Services />, name: ' Jyver(Worker)' },
  { path: '/privacy', element: <Privacy/>, name: ' privacy' },
  { path: '/msa', element: <Msa/>, name: ' msa' },
  { path: '/terms', element: <Terms/>, name: ' Terms' },
  { path: '/privacy-ca-applicant', element: <Terms/>, name: ' Terms' },
  { path: '/contact', element: <Contact/>, name: ' Contact' },
 
];

export default routes;
