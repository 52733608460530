import React from 'react';
import "../styles/featureSection.css";
import dsd from '../Attachments/Custom SVG/dsd-design.646c188da6e27139812fe1bd45db576a.svg';
import turnover from '../Attachments/Custom SVG/dsd-turnover.98bc4f8c429215b067170936bb17b151 (1).svg';
import retailers from '../Attachments/Custom SVG/retailers-optimize.6536fbc8b2a1239aa787a0b9e4b47bf3.svg';

interface Feature {
  image: string;
  title: string;
  body: string;
  buttonText?: string;
  buttonLink?: string;
}

interface FeatureSectionProps {
  sectionTitle?: string;
  features?: Feature[];
}

const defaultFeatures: Feature[] = [
  {
    image: retailers,
    title: 'Eliminate Headaches',
    body: 'Optimize scheduling, improve communication & track in-store results in Jyve Partner Platform.',
  },
  {
    image: turnover,
    title: 'Design a Better System',
    body: 'Build a flexible go-to-market capability that gives you full visibility to store-level work.',
  },
  {
    image: dsd,
    title: 'Optimize Labor Costs',
    body: 'Eliminate turnover and reduce time managers spend on scheduling.',
  },
];

const FeatureSection: React.FC<FeatureSectionProps> = ({
  sectionTitle = "We built Jyve to solve industry challenges",
  features = defaultFeatures,
}) => {
  return (
    <section className="py-16 px-4 rounded-lg mb-9">
      <h2 className="text-white text-3xl font-bold text-center mb-8">
        {sectionTitle}
      </h2>

      <div className="max-w-screen-xl mx-auto grid grayArea grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-start p-6 rounded-lg"
          >
            <div className="mb-4">
              <img 
                src={feature.image} 
                alt={feature.title} 
                className="w-16 h-16 object-contain" 
              />
            </div>

            <h3 className="text-xl font-semibold text-900 mb-2">{feature.title}</h3>

            <p className="text-gray-500 text-sub">{feature.body}</p>

            {feature.buttonText && feature.buttonLink && (
              <a href={feature.buttonLink} target="_blank" rel="noopener noreferrer">
                <button className="mt-4 px-6 py-3 bg-green-900 text-white rounded-lg shadow-lg transition duration-200">
                  {feature.buttonText}
                </button>
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

FeatureSection.defaultProps = {
  sectionTitle: "We built Jyve to solve industry challenges",
  features: defaultFeatures,
};

export default FeatureSection;
