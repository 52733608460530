import React, { useState, useEffect } from "react";
import "../styles/contentSwitch.css";

type Content = {
  imageUrl: string;
  title: string;
};

type ContentSwitcherProps = {
  contentData: Content[];
  interval?: number; 
  headerTitle?: string;
  headerDescription?: string;
};

const ContentSwitcher: React.FC<ContentSwitcherProps> = ({
  contentData,
  interval = 3500, 
  headerTitle = "Get Started with Three Easy Steps",
  headerDescription = "Here are three easy steps to begin your journey",
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const switchInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, interval);

    return () => clearInterval(switchInterval);
  }, [contentData.length, interval]);

  const handleButtonClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="wrapper-container">
      <div className="flex flex-col lg:flex-row justify-between items-center w-full space-y-8 lg:space-y-0">
        
        <div className="tab-pane-tab right-mar w-full">
          <div className="hrDiv lg:w-1/2 w-full mb-8">
            <h1 className="lg:text-6xl mb-7 font-bold why-head mb-4">{headerTitle}</h1>
            <hr className="my-4 border-t-2 border-gray-500" />
            <p className="text-lg">{headerDescription}</p>
          </div>
          {contentData.map((content, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(index)}
              className={`w-full benefits_button px-6 py-3 text-lg font-semibold rounded-lg transition-all duration-300 transform hover:scale-105 
              ${currentIndex === index ? "bg-green-500 text-white border-teal-500 border-2" : "bg-blue-500 text-white"}`}
            >
              <div className="flex items-center w-full justify-start">
                <span className="text-left">{content.title}</span>
                <i className="ml-auto mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12l5 5L20 7"
                    />
                  </svg>
                </i>
              </div>
            </button>
          ))}
        </div>

   
        <div className="flex flex-col items-start space-y-4 w-full lg:w-3/5">
          <div className="benefits_tab_image-wrapper overflow-hidden rounded-lg shadow-lg animate-fadeInImage">
            <img
              src={contentData[currentIndex].imageUrl}
              alt={`Content ${currentIndex + 1}`}
              className="benefits-img"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSwitcher;
