import React from "react";

interface LineChartProps {
  title?: string;
  description?: string;
  imageSrc?: string;
  altText?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  title = "Boost Operational Efficiency", 
  description = "By tapping into Jyve's skilled labor pool, you can optimize your operations, improve in-store execution, and enhance the customer experience—all without the complexities of traditional staffing models.", // Default description
  imageSrc = require("../Attachments/linechart.png"), 
  altText = "Operational Efficiency"
}) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col mt-16 md:flex-row items-center">
        <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
          <img
            src={imageSrc}
            alt={altText}
            className="w-full rounded-lg shadow-lg"
          />
        </div>

        <div className="flex flex-col items-center justify-center md:w-1/2 sm:w-full text-center md:text-left">
          <h2 className="md:text-5xl sm:text-4xl font-bold text-white leading-tight">{title}</h2>
          <p className=" text-base sm:text-lg md:text-xl text-gray-300 mt-6 leading-relaxed max-w-md">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
