import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_usa from "@amcharts/amcharts5-geodata/usaLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import jsonData from "../utils/mapData.json";

const USMap: React.FC = () => {
  useLayoutEffect(() => {
    const root = am5.Root.new("usMap");

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "none",
        panY: "none",
        wheelX: "none",
        wheelY: "none",
        projection: am5map.geoAlbersUsa(),
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_usa,
      })
    );

    const tealColor = am5.color(0x1e5950);
    const deeperTealColor = am5.Color.brighten(tealColor, -0.2);

    const dataWithIds = jsonData.query_result.data.rows.map((row) => ({
      id: `US-${row.state.toUpperCase()}`,
      name: row.state,
      totalStores: row.total_stores,
      totalJyvers: row.total_jyvers,
      totalJobs: row.total_jobs,
    }));

    const maxJyvers = Math.max(...dataWithIds.map((state) => state.totalJyvers));
    const minJyvers = Math.min(...dataWithIds.map((state) => state.totalJyvers));

    polygonSeries.mapPolygons.template.setAll({
      fill: tealColor,
      tooltipText: "{name}: {totalStores} Stores, {totalJyvers} Jyvers, {totalJobs} Jobs",
    });

    polygonSeries.events.on("datavalidated", () => {
      polygonSeries.mapPolygons.each((polygon) => {
        const dataItem = polygon.dataItem;
        if (dataItem?.dataContext && typeof dataItem.dataContext === "object") {
          const dataContext = dataItem.dataContext as { id?: string; name?: string };
          const stateData = dataWithIds.find((state) => state.id === dataContext.id);

          if (stateData) {
            let opacity;
            if (stateData.totalJyvers <= 200) {
              opacity = 0.5 + (stateData.totalJyvers / 200) * 0.25; 
            } else {
              opacity = 0.5 + ((stateData.totalJyvers - 200) / (maxJyvers - 200)) * 0.5; 
            }

            polygon.setAll({
              fill: am5.color(0x37b2a0),
              opacity: opacity,
              tooltipText: `${stateData.name}:\n- ${stateData.totalStores} Stores\n- ${stateData.totalJyvers} Jyvers\n- ${stateData.totalJobs} Jobs`,
            });
          } else {
       
            polygon.setAll({
              fill: am5.color(0xE0E0E0),  
              tooltipText: "Coming Soon",
            });

            polygon.animate({
              key: "fill",
              to: am5.color(0x888888),  
              duration: 2000,
              easing: am5.ease.inOut(am5.ease.cubic),
            });
          }
        }
      });
    });

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="usMap" style={{ width: "100%", height: "500px" }} />;
};

export default USMap;
