import React, { useEffect, useRef } from "react";
import "../styles/stackingtwo.css";  
import frameA from "../Attachments/Jyve-shopping-cart_1548363612.jpg";
import frameB from "../Attachments/Jyve-blurb-1080x675.jpg";
import frameC from "../Attachments/jyve-3.jpeg";

const StackingTwo: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const rows = containerRef.current.querySelectorAll(".stacking-row");

        rows.forEach((row, index) => {
          if (containerTop <= -index * 100) {
            row.classList.add("stacked");
          } else {
            row.classList.remove("stacked");
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
    
      <header className="text-white text-center  mt-12">
      <h1 className=" sm:text-5xl md:text-6xl font-semibold">How Jyve Supports You</h1>
      <p className="text-base sm:text-lg md:text-xl mt-2">Enhancing Retail, Brands, and Events with Jyve</p>

      </header>

   
      <div ref={containerRef} className="stacking-container">
        <div className="stacking-row row-1" id="retailers">
          <div className="header-image-container">
            <div className="left">
              <div className="icon-circle">
                <span>01</span>
              </div>
              <div className="subheading">
                <p className="subheading-text">Start Working Right Away</p>
              </div>
              <h3 className="bold-heading"> Instant Access to Jobs</h3>
            </div>
            <div className="right">
              <img src={frameB} alt="Row 1 Image" />
            </div>
          </div>

          <div className="line-container">
            <hr className="line-separator" />
          </div>

          <div className="body-container">
            <p className="body-text">
            ith Jyve, finding and starting work has never been easier. Whether you’re interested in shelf-stacking, inventory management, or other on-demand tasks, the app gives you immediate access to job opportunities. No long application processes or waiting around—just pick a task, complete it, and get paid fast. 
            </p>
          </div>
        </div>

        <div className="stacking-row row-2" id="brands">
          <div className="header-image-container">
            <div className="left">
              <div className="icon-circle">
                <span>02</span>
              </div>
              <div className="subheading">
                <p className="subheading-text">Your Schedule, Your Choice

</p>
              </div>
              <h3 className="bold-heading">Flexibility to Choose Where and When You Work</h3>
            </div>
            <div className="right">
              <img src={frameC} alt="Row 2 Image" />
            </div>
          </div>

          <div className="line-container">
            <hr className="line-separator" />
          </div>

          <div className="body-container">
            <p className="body-text">
            Jyve puts the power of choice in your hands. Whether you want to work mornings, afternoons, weekends, or even late nights, you can choose the time that works best for you. Plus, with the ability to select where you work, Jyvers have the freedom to pick jobs that are convenient for them.
            </p>
          </div>
        </div>

        <div className="stacking-row row-3" id="events">
          <div className="header-image-container">
            <div className="left">
              <div className="icon-circle">
                <span>03</span>
              </div>
              <div className="subheading">
                <p className="subheading-text">Get Paid ASAP for the Jobs You Complete

</p>
              </div>
              <h3 className="bold-heading">Quick Payments for Quick Work</h3>
            </div>
            <div className="right">
              <img src={frameA} alt="Row 3 Image" />
            </div>
          </div>

          <div className="line-container">
            <hr className="line-separator" />
          </div>

          <div className="body-container">
            <p className="body-text">
            One of the biggest advantages of working with Jyve is the instant payment feature. After you complete a job like stock auditing or other duties, you get paid right away—no waiting for a paycheck at the end of the day.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StackingTwo;
