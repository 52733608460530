import React from "react";
import Logo from "../Attachments/whiteLogo.png";
import { Link } from 'react-router-dom';  

const Footer: React.FC = () => {
  return (
    <footer className="px-4 divide-y bg-dark  dark:text-white">
      <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <div className="lg:w-1/3">
          <a
            rel="noopener noreferrer"
            href="#"
            className="flex justify-center space-x-3 lg:justify-start"
          >
            <div className="flex items-center justify-center w-26 h-26 dark:bg-violet-600">
              <img
                src={Logo}
                alt="icon"
                className="flex-shrink-0 w-26 h-9 dark:text-white"
              />
            </div>
            <span className="self-center text-2xl font-semibold text-white"></span>
          </a>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
          <div className="space-y-3">
            <ul className="space-y-1">
              <li className="text-white" >
                <Link to="/workers">
                 Jyvers
                 </Link>
                 <li className="text-white"><Link to="/brands">
                Brands
                </Link>
              </li>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <ul className="space-y-1">
              
              <li className="text-white">
              
              <Link to="/contact">    Contact Us</Link>
              
              </li>
              <li className="text-white">
              <Link to="/terms">
                  Terms & Conditions</Link>
              
              </li>
              <li className="text-white">
              <Link to="/privacy">
                  Privacy Policy</Link>
              
              </li>
             
            </ul>
          </div>
          <div className="space-y-3">
            <ul className="space-y-1">
              
              <li className="text-white">
              <Link to="/msa">
                 Master Service Agreement
                 </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <div className="uppercase font-bold text-white">Social media</div>
            <div className="flex justify-start space-x-3">
              <a
                rel="noopener noreferrer"
                href="https://www.facebook.com/JyveCorporation/"
                title="Facebook"
                className="flex items-center p-1 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  className="w-5 h-5 fill-current"
                >
                  <path d="M32 16c0-8.839-7.167-16-16-16-8.839 0-16 7.161-16 16 0 7.984 5.849 14.604 13.5 15.803v-11.177h-4.063v-4.625h4.063v-3.527c0-4.009 2.385-6.223 6.041-6.223 1.751 0 3.584 0.312 3.584 0.312v3.937h-2.021c-1.984 0-2.604 1.235-2.604 2.5v3h4.437l-0.713 4.625h-3.724v11.177c7.645-1.199 13.5-7.819 13.5-15.803z"></path>
                </svg>
              </a>
              
              <a
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/jyve-corporation/"
                title="Instagram"
                className="flex items-center p-1 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  className="w-5 h-5 fill-current"
                >
                  <path d="M16 0c-4.349 0-4.891 0.021-6.593 0.093-1.709 0.084-2.865 0.349-3.885 0.745-1.052 0.412-1.948 0.959-2.833 1.849-0.891 0.885-1.443 1.781-1.849 2.833-0.396 1.020-0.661 2.176-0.745 3.885-0.077 1.703-0.093 2.244-0.093 6.593s0.021 4.891 0.093 6.593c0.084 1.704 0.349 2.865 0.745 3.885 0.412 1.052 0.959 1.948 1.849 2.833 0.885 0.891 1.781 1.443 2.833 1.849 1.020 0.391 2.176 0.661 3.885 0.745 1.703 0.077 2.244 0.093 6.593 0.093s4.891-0.021 6.593-0.093c1.704-0.084 2.865-0.355 3.885-0.745 1.052-0.412 1.948-0.959 2.833-1.849 0.891-0.885 1.443-1.781 1.849-2.833 0.391-1.020 0.661-2.176 0.745-3.885 0.077-1.703 0.093-2.244 0.093-6.593s-0.021-4.891-0.093-6.593c-0.084-1.709-0.355-2.865-0.745-3.885-0.412-1.052-0.959-1.948-1.849-2.833-0.885-0.891-1.781-1.443-2.833-1.849-1.020-0.396-2.176-0.661-3.885-0.745-1.703-0.077-2.244-0.093-6.593-0.093z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 text-sm text-center">
        <span className="text-white">© 2024 Jyve. Jyve® is a registered trademark of Jyve.</span>
      </div>
    </footer>
  );
};

export default Footer;
